import Img from 'gatsby-image';
import React from 'react';
import styled from '../../styles/styled-components';
import Image from '../../types/Image';

interface Order {
  order: number;
}
const InfoSection = styled.div`
  display: flex;
  margin: 32px 0;
`;
const ImgStyled = styled(Img)`
  flex: 0 0 180px;
  order: ${(p: Order) => p.order};
  ${(p: Order) =>
    p.order &&
    `
      margin-left: 24px;
    `}
`;
const Content = styled.div`
  order: ${(p: Order) => p.order};
  ${(p: Order) =>
    p.order &&
    `
      margin-left: 24px;
    `}
`;
const Title = styled.h3`
  color: ${p => p.theme.primary500};
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  items: Array<{
    image: Image;
    heading: string;
    description: string;
  }>;
}

const ImageDescriptionItems = ({ items }: Props) => (
  <div>
    {items.map((infoItem, index) => (
      <InfoSection key={infoItem.heading}>
        <ImgStyled
          fixed={infoItem.image.childImageSharp.fixed}
          order={index % 2}
        />
        <Content order={(index + 1) % 2}>
          <Title>{infoItem.heading}</Title>
          <div>{infoItem.description}</div>
        </Content>
      </InfoSection>
    ))}
  </div>
);

export default ImageDescriptionItems;
