import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CallToActionContent from '../components/UI/CallToActionContent';
import HorizontalDescriptionItems from '../components/UI/HorizontalImageDescriptionItems';
import ImageDescriptionItems from '../components/UI/ImageDescriptionItems';
import SectionContainer from '../components/UI/SectionContainer';
import styled from '../styles/styled-components';
import DataMarkdown from '../types/DataMarkdown';
import Image from '../types/Image';

const Title = styled.h1`
  text-align: center;
  color: ${p => p.theme.primary500};
  margin: 32px 0 48px;
`;
const InfoSection = styled.div`
  display: flex;
  margin: 32px 0;
`;
const StepsSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ContentImage = styled.div`
  flex: 1 0 150px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: ${props => props.theme.gray300};
  margin: 32px;
`;
const ContainerHorizontalDescription = styled(SectionContainer)`
  background-color: ${p => p.theme.gray100};
  margin-bottom: 64px;
`;
const HorizontalDescriptionStyled = styled(HorizontalDescriptionItems)`
  max-width: 980px;
`;
const CallToActionStyled = styled(CallToActionContent)``;

export const InfoPageTemplate = () => {
  return <></>;
};

interface Props {
  html: string;
  frontmatter: {
    title: string;
    subheading: string;
    image: Image;
    mainInfo: Array<{
      image: Image;
      heading: string;
      description: string;
    }>;
  };
}

const InfoPage = ({ data }: DataMarkdown<Props>) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <Title>{post.frontmatter.title}</Title>
      <SectionContainer>
        <ImageDescriptionItems items={post.frontmatter.mainInfo} />
      </SectionContainer>
      <CallToActionContent />
    </Layout>
  );
};

export default InfoPage;

export const infoPageQuery = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainInfo {
          heading
          description
          image {
            childImageSharp {
              fixed(width: 160, height: 160, quality: 75) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
