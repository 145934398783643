import Img from 'gatsby-image';
import React from 'react';
import 'typeface-muli';
import styled from '../../styles/styled-components';
import Image from '../../types/Image';

const HorizontalDescriptionStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoSection = styled.div`
  margin: 32px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImgStyled = styled(Img)`
  flex: 0 0 180px;
`;
const Title = styled.h3``;
const Description = styled.div`
  color: ${p => p.theme.gray700};
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  items: Array<{
    image: Image;
    heading: string;
    description: string;
    link?: string;
  }>;
}

const HorizontalDescriptionItems = ({ items }: Props) => (
  <HorizontalDescriptionStyled>
    {items.map((item, index) => (
      <InfoSection key={item.heading}>
        <ImgStyled fixed={item.image.childImageSharp.fixed} />
        <Title>{item.heading}</Title>
        <Description>{item.description}</Description>
        {!!item.link && <a href={item.link}>Ver más</a>}
      </InfoSection>
    ))}
  </HorizontalDescriptionStyled>
);

export default HorizontalDescriptionItems;
